<script>
    import ProfileSidebar from "./ProfileSidebar";
    import {Card, instance as StripeInstance} from "vue-stripe-elements-plus";
    import {mapState} from "vuex";
    /**
     * Profile component
     */
    export default {
        page: {
            title: 'Λογαριασμός',
        },
        components: {ProfileSidebar, Card},
      computed:{
          ...mapState({
            user: state => state.auth.currentUser
          }),

        canCompletePayment(){
            if(!this.invoice) return true; // for simple receipt instead of invoice we can always proceed
            if(!this.user.userCompany) return false;

            if(!this.user.userCompany.VAT || !this.user.userCompany.companyName || !this.user.userCompany.address || !this.user.userCompany.mobile) return false;
            return true;

        }
      },
        data() {
            return {
                invoice: true,
                transaction: null,
                userTransactionId: this.$route.params.userTransactionId,
                stripeKey: null,
                completed: false,
                stripeOptions: {
                    iconStyle: 'solid',
                    hidePostalCode: true,
                    style: {
                        base: {
                            color: '#32325D',
                            fontWeight: 500,
                            fontFamily: 'inherit',
                            fontSize: '16px',
                            fontSmoothing: 'antialiased',
                            '::placeholder': {
                                color: '#CFD7DF',
                            },
                            ':-webkit-autofill': {
                                color: '#e39f48',
                            },
                        },
                        invalid: {
                            color: '#E25950',

                            '::placeholder': {
                                color: '#FFCCA5',
                            },
                        },
                    },
                },
                submitted: false,
                error: ''
            }
        },
        created: function(){
            this.$store.subscribe((mutation, state) => {
                if(mutation.type === 'auth/SET_CURRENT_USER'){
                    this.user = this.$store.state.auth.currentUser;
                }
            });
            this.getPrice();
            this.getTransactionStatus();
            this.getTransaction();
            this.$store.commit('ui/setActiveItem', {value: this.$t('routes.pay-transaction'), index: 0});
        },
        methods:{
            getPrice(){
                this.$axios.get('/users/prices').then(result=>{
                    this.stripeKey = result.data.stripeKey;
                });
            },

            getTransactionStatus(){
                this.$axios.get(`/users/transactions/${this.userTransactionId}/status`).then( result => {
                    if(result.data.message === 'STATUS_UPDATED' || result.data.message === 'PURCHASED') this.$router.push({name: 'user-subscription'});
                    this.$store.dispatch('auth/refetchUser');
                }).catch(e => {
                    this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των δεδομένων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                });
            },
            getTransaction(){
                this.$axios.get(`/users/transactions/${this.userTransactionId}`).then( result => {
                    this.transaction = result.data;
                    if(this.transaction.status !== 'PENDING') this.$router.push({name: 'user-subscription'});
                }).catch(e => {
                    this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των δεδομένων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                });
            },
            makePayment(){
                this.submitted = true;
                this.error = '';

                this.$axios.post(`/users/transactions/${this.transaction.id}`, {invoice: this.invoice}).then(result=> {
                    let clientSecret = result.data.client_secret;
                    StripeInstance.confirmCardPayment(clientSecret, {
                        payment_method: {
                            card: this.$refs.innerStripeCard.$refs.element._element,
                            billing_details: {
                                name: this.user.firstName + ' ' + this.user.lastName,
                                email: this.user.email
                            }
                        }
                    }).then(result => {
                        if (result.error) {
                            // Show error to your customer (e.g., insufficient funds)
                            this.submitted = false;
                            this.error = result.error.message;
                            this.error += ' Εάν πιστεύετε ότι έχει γίνει λάθος, επικοινωνήστε μαζί μας στο info@athlisis.com';
                        } else {
                            // The payment has been processed!
                            if (result.paymentIntent.status === "succeeded") {
                                this.$notify({group: 'athlisis-notifications', type:'success', title: this.$t('success.title'), text: 'Η συναλλαγή ολοκληρώθηκε επιτυχώς!'});
                                this.getTransactionStatus(true);
                            }
                        }
                    });
                }).catch(e => {
                    this.submitted = false;
                    this.error = 'Κάτι πήγε λάθος.';
                    this.error += ' Επικοινωνήστε μαζί μας στο info@athlisis.com';
                });
            }



        }
    }
</script>
<template>
    <div id="pay-transaction">
        <div id="payTransaction" class="row">
            <ProfileSidebar></ProfileSidebar>
            <div class="col-lg-9 col-xl-9">
                <b-card>
                    <b-card-title>Πληρωμή συναλλαγής</b-card-title>
                    <b-card-sub-title>Πληρώστε με ασφάλεια μέσω του ασφαλούς συστήματος πληρωμών.</b-card-sub-title>
                    <div v-if="transaction">
                        <b-overlay :show="submitted">
                            <div class="container-payment mt-4 shadow-sm">
                                <h3><i class="icon-lock"></i> Πληρωμή συναλλαγής</h3>
                                <p>Πληρώστε άμεσα με την πιστωτική / χρεωστική σας κάρτα μέσω του ασφαλούς συστήματος πληρωμών μας.</p>
                                <p><strong>Κόστος συναλλαγής: </strong> {{transaction.amount.toFixed(2)}}€</p>
                                <p><strong>Πληρωμή έως: </strong> {{formatDateWithoutHour(transaction.dueDate)}}</p>

                              <div class="pt-2 pb-2">
                                <label for="invoice">Αποδεικτικό:</label>
                                <b-form-radio-group id="invoice" v-model="invoice">
                                  <b-form-radio :value="false">Απόδειξη</b-form-radio>
                                  <b-form-radio :value="true">Τιμολόγιο</b-form-radio>
                                </b-form-radio-group>
                              </div>

                              <b-alert show variant="danger" v-if="!canCompletePayment">
                                Για να συνεχίσετε την πληρωμή σας με τιμολόγιο, παρακαλώ συμπληρώστε τα υποχρεωτικά στοιχεία τιμολόγησής σας
                                (ΑΦΜ, διεύθυνση, κινητό τηλέφωνο, επωνυμία). <router-link :to="{name: 'company'}">Πατήστε εδώ για να τα συμπληρώσετε.</router-link>
                              </b-alert>
                                <card ref="innerStripeCard" class='stripe-card pb-3 pt-3' :class='{ completed }' :stripe="stripeKey" :options='stripeOptions' @change='completed = $event.complete'/>
                                <b-button variant="success" class='pay-with-stripe ' :disabled='!completed || !canCompletePayment' @click='makePayment'><i class="icon-lock"></i> Ολοκλήρωση πληρωμής {{ transaction.amount.toFixed(2)}}€</b-button>

                                <b-alert variant="danger" :show="error !== ''" class="mt-3">{{error}}</b-alert>
                            </div>

                        </b-overlay>
                    </div>
                </b-card>
            </div>
            <!-- end card-box-->

            <!-- end col -->
        </div>
        <!-- end row-->
    </div>
</template>
<style lang="scss">
    #payTransaction{
        .container-payment{
            background-color:#fefefe;
            border-radius:5px;
            max-width: 600px;
            padding:20px;
            text-align: left;
        }
        .stripe-card {

        }
        .StripeElement {
            box-sizing: border-box;

            height: 40px;

            padding: 10px 12px;

            border: 1px solid transparent;
            border-radius: 4px;
            background-color: white;

            box-shadow: 0 1px 3px 0 #e6ebf1;
            -webkit-transition: box-shadow 150ms ease;
            transition: box-shadow 150ms ease;
        }

        .StripeElement--focus {
            box-shadow: 0 1px 3px 0 #cfd7df;
        }

        .StripeElement--invalid {
            border-color: #fa755a;
        }

        .StripeElement--webkit-autofill {
            background-color: #fefde5 !important;
        }

        /*.container-payment{*/
        /*    background-color:#f3f3f3;*/
        /*}*/
    }

</style>
